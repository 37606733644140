import { useCallback, useRef } from "react";

import { MinusIcon, PlusIcon } from "~/components/svg/icons";
import { cn } from "~/utils/classnames";

import "./NumberSelector.css";
import type { NumberSelectorProps } from "./NumberSelector.types";

/**
 * Composant de selection d'un nombre entier avec boutons + et -
 */
export const NumberSelector = ({
  id,
  label,
  onStepUp,
  onStepDown,
  min = 0,
  max = 9999,
  onBlur,
  className,
  loading = false,
  ...props
}: NumberSelectorProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleMinusClick = useCallback(() => {
    if (
      inputRef.current &&
      Number(inputRef.current.value) > min &&
      Number(inputRef.current.value) <= max
    ) {
      inputRef.current.stepDown();
    }
    if (onStepDown && inputRef.current) {
      onStepDown(Number(inputRef.current.value));
    }
  }, [onStepDown, min, max]);

  const handlePlusClick = useCallback(() => {
    if (
      inputRef.current &&
      Number(inputRef.current.value) >= min &&
      Number(inputRef.current.value) < max
    ) {
      inputRef.current.stepUp();
    }
    if (onStepUp && inputRef.current) {
      onStepUp(Number(inputRef.current.value));
    }
  }, [onStepUp, min, max]);

  let defaultValueProps: { defaultValue?: string | number } = {};

  if (!props.value && props.defaultValue) {
    defaultValueProps = {
      defaultValue: props.defaultValue || 1,
    };
  }

  return (
    <div className={cn("NumberSelector", className, loading ? "NumberSelector--loading" : "")}>
      {label ? (
        <label htmlFor={id} className="NumberSelector-label">
          {label}
        </label>
      ) : null}
      <div className="NumberSelector-container">
        <button
          type="button"
          className="NumberSelector-minusButton"
          onClick={handleMinusClick}
          disabled={!!(props.value && Number(props.value as string) <= 1)}
        >
          <MinusIcon className="NumberSelector-minusIcon svgIcon" />
        </button>
        <input
          type="number"
          min={min}
          max={max}
          className="NumberSelector-input"
          ref={inputRef}
          onBlur={(e) => {
            if (Number(e.currentTarget.value) > max) {
              e.currentTarget.value = max.toString();
            }
            if (Number(e.currentTarget.value) < min) {
              e.currentTarget.value = min.toString();
            }
            if (Number(props.step) && Number(e.currentTarget.value) % Number(props.step) !== 0) {
              e.currentTarget.value = (
                Math.round(Number(e.currentTarget.value) / Number(props.step)) * Number(props.step)
              ).toString();
            }
            if (onBlur) {
              onBlur(e);
            }
          }}
          {...defaultValueProps}
          {...props}
        />
        <button type="button" className="NumberSelector-plusButton" onClick={handlePlusClick}>
          <PlusIcon className="NumberSelector-plusIcon svgIcon" />
        </button>
      </div>
    </div>
  );
};
